import React from 'react';
import HeadLogo from '../../assets/images/500x500.png';
import { Link} from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

const Header = () => {
  return (
    <AppBar position="static">
        <Toolbar 
            style={{
                    display:"flex",
                    backgroundColor:"#fdfdfd",
                    justifyContent:"space-between",
                    alignContent:"center",
                    height:"10vh",
                    fontFamily:"inter"
                }}             
                >
                <Link to="/menu" 
                    style={{
                        textDecoration:"none", 
                        fontSize:"20px", 
                        color:"black", 
                        marginLeft:"10px"
                    }}
                    >
                    <b style={{fontWeight:"bolder", fontSize:"larger", fontFamily:"inter"}}>
                        <span>Nostalgia</span>
                        <span style={{color:"#FCC207"}}>Kitchen </span>
                    </b>

                </Link>
                <div
                    style={{
                        display:"flex",
                        gap:"10px",
                        marginRight:"20px",
                        fontWeight:"bolder",
                        fontSize:"larger",
                            fontFamily:"inter"}}
                >
                        <Link to="/menu" 
                            style={{
                                textDecoration:"none", 
                                fontSize:"20px", 
                                color:"black", 
                            }}
                            >
                            Menu
                        </Link>
                
                        <Link to="/weeklymenu" 
                            style={{
                                textDecoration:"none", 
                                fontSize:"20px", 
                                color:"black", 
                            }}
                            >
                            Weekly Menu
                        </Link>
                        {/* <Link to="/menu" >
                            <img src={HeadLogo} style={{height:"40vh"}}/>
                        </Link> */}
                        
                        <Link to="/about" 
                            style={{
                                textDecoration:"none", 
                                fontSize:"20px", 
                                color:"black", 
                            }}
                            >
                            About
                        </Link>
                        
                        {/* <Link to="/contact" 
                            style={{
                                textDecoration:"none", 
                                fontSize:"20px", 
                                color:"black", 
                            }}
                            >
                            Contact
                        </Link> */}
                </div>
            


        </Toolbar>
    </AppBar>
  )
}

export default Header